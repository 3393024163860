export default function funcName(aGrills, fnsetData) {
  var sMarketImgUrl = "https://api.mapy.cz/img/api/marker/drop-red.png";

var oMap = new SMap(JAK.gel("map"));
oMap.addControl(new SMap.Control.Sync());
oMap.addDefaultLayer(SMap.DEF_BASE).enable(); 
var oMouseSettings = new SMap.Control.Mouse(SMap.MOUSE_PAN | SMap.MOUSE_WHEEL | SMap.MOUSE_ZOOM);
oMap.addControl(oMouseSettings); 

var layerWithMarkers = new SMap.Layer.Marker();     /* Vrstva se značkami */
var aPositions = [];
// data pro markery

var markers = aGrills ? aGrills : [];

var marker_mouse_in = function(e){
    e.target.classList.add("mark-hue");
  };
  
  var marker_mouse_out = function(e){
    e.target.classList.remove("mark-hue");
  };
  

// vytvoreni markeru
markers.forEach(function(marker) {
	var c = SMap.Coords.fromWGS84(marker.coords); /* Souřadnice značky, z textového formátu souřadnic */
    
    var oMarkerPoint = JAK.mel("div", {}, { position:"absolute", left:"0px", top:"2px", textAlign:"center", width:"22px", color:"white", fontWeight:"bold", cursor: "pointer" });
	var oMarkerPicture = JAK.mel("img", { src: sMarketImgUrl } );
    oMarkerPoint.appendChild(oMarkerPicture);
    var oMarkerDesc = JAK.mel("div", {}, {position:"absolute", left:"0px", top:"2px", textAlign:"center", width:"22px", color:"white", fontWeight:"bold"});
    oMarkerDesc.innerHTML = marker.id;
    oMarkerPoint.appendChild(oMarkerDesc);

    JAK.Events.addListener(oMarkerPoint, "mouseover", marker_mouse_in);
    JAK.Events.addListener(oMarkerPoint, "mouseout", marker_mouse_out );

  var options = {
        url: oMarkerPoint,
        title: marker.name,
        anchor: {left:10, bottom: 1}  /* Ukotvení značky za bod uprostřed dole */
    }
    
    // duletize je prirazeni id jednotlivemu markeru - vlastni id, jinak se generuje nahodne
    var oSMarker = new SMap.Marker(c, marker.id, options);
    
    aPositions.push(c);
    layerWithMarkers.addMarker(oSMarker);
});

// zobrazime a povolime vrstvu - pokud by se vrstva povolila pred vkladanim markeru, tak by se s kazdym vlozenym markerem prekreslovala mapa a pocitaly pozice vsech markeru
oMap.addLayer(layerWithMarkers);                          /* Přidat ji do mapy */
layerWithMarkers.enable();                         /* A povolit */

var oCenterPoint = oMap.computeCenterZoom(aPositions); /* Spočítat pozici mapy tak, aby značky byly vidět */
oMap.setCenterZoom(oCenterPoint[0], oCenterPoint[1]);        

// poslouchani na kliknuti u markeru
oMap.getSignals().addListener(this, "marker-click", function(oEvent) {
  var marker = oEvent.target;
  var id = marker.getId();
  fnsetData(id);
  // for (var i = 0; i < markers.length; i++) {
  // 	if (markers[i].id == id) {
  //   	alert(markers[i].name);
  //     break;
  //   }
  // }
});

 }